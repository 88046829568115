import { Component, inject, signal } from "@angular/core";
import { BaseEntity } from "./base.component";
import { MatDialog } from "@angular/material/dialog";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ConfirmDialogComponent, HttpCrudService, IHasNumberId } from "@webapp-nx-repo/lib-shared-common";



@Component({
    template: ''
})
export abstract class BaseCrudEntity<T extends IHasNumberId> extends BaseEntity {
    
    protected readonly confirmDialog = inject(MatDialog);
    
    readonly saving = signal<boolean>(false);

    protected abstract get_item(): T;
    protected abstract get_http_crud_service(): HttpCrudService<T>;

    protected abstract on_save_confirm_dialog_mgs(): string[];    // [`Salvo il prodotto \"${this.formData.controls.name.value}\" ?`]    
    protected abstract on_insert_complete_msg(): string;                // 'Prodotto inserito'    
    protected abstract on_update_complete_msg(): string;                // 'Prodotto aggiornato'
    protected on_insert_complete_do_back(): boolean { return true; };   // do back on complete ?
    protected on_update_complete_do_back(): boolean { return true; };   // do back on complete ?
    
    protected abstract on_delete_confirm_dialog_mgs(): string[];    // [`Vuoi eliminare il prodotto \"${this.data().product.name}\" ?`]    
    protected abstract on_delete_complete_msg(): string;                // 'Prodotto eliminato'
    protected on_delete_complete_do_back(): boolean { return true; };   // do back on complete ?

    protected reload_cache(): boolean { return false; }; // reload all_with_cache$ on iud
    protected on_insert_complete_fn(x: T): void {}; // extra logic
    protected on_update_complete_fn(x: T): void {}; // extra logic
    protected on_delete_complete_fn(id: number): void {}; // extra logic

    save() {
        const dialogRef = this.confirmDialog.open(ConfirmDialogComponent, {data: {
            title: 'Salvataggio', 
            messages: this.on_save_confirm_dialog_mgs(),
        }});

        dialogRef.afterClosed().subscribe( result => { 
            if(result) {
                const item = this.get_item();
                item.id === 0 ? this._insert(item) : this._update(item);
            }
        });
        
    }

    private _insert(item: T) {
        
        this.saving.set(true);
        this.get_http_crud_service().insert(item)
            .pipe(
                takeUntilDestroyed(this.destroyRef),
            ).subscribe({
                next: (srvItem: T) => {
                    this.on_insert_complete_fn(srvItem);
                    this.saving.set(false);
                    this.onComplete(this.on_insert_complete_msg(), this.on_insert_complete_do_back());
                },
                error: (err: any) => {
                    this.saving.set(false);
                    this.onError(err, 'insert', 'errore nell\'inserimento');
                },
            });
        
    }

    private _update(item: T) {
        
        this.saving.set(true);
        this.get_http_crud_service().update(item)
            .pipe(
                takeUntilDestroyed(this.destroyRef),
            ).subscribe({
                next: () => {
                    this.saving.set(false);
                    this.on_update_complete_fn(item);
                    this.onComplete(this.on_update_complete_msg(), this.on_update_complete_do_back());
                },
                error: (err: any) => {
                    this.saving.set(false);
                    this.onError(err, 'update', 'errore nell\'aggiornamento');
                },
            });

    }

    delete() {
        const dialogRef = this.confirmDialog.open(ConfirmDialogComponent, {data: {
            title: 'Elimina', 
            messages: this.on_delete_confirm_dialog_mgs(),
        }});
        dialogRef.afterClosed().subscribe( result => { 
            if(result) {
                const item = this.get_item();
                this._delete(item.id);
            }
        });
    }
    private _delete(id: number) {
        
        this.saving.set(true);
        this.get_http_crud_service().delete(id)
            .pipe(
                takeUntilDestroyed(this.destroyRef),
            ).subscribe({
                next: () => {
                    this.saving.set(false);
                    this.on_delete_complete_fn(id);
                    this.onComplete(this.on_delete_complete_msg(), this.on_delete_complete_do_back());
                },
                error: (err: any) => {
                    this.saving.set(false);
                    this.onError(err, 'delete', 'errore nella cancellazione');
                },
            });
            
    }
}
